import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

export default function Presentation({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtAboutUsHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      <div className="bg-white">
        <Container className="p-4">
          {/* <h1 className="text-info font-weight-600"> */}
          {/*  Présentation DatArt */}
          {/* </h1> */}
          <Row>
            <Col md={7}>
              <h2 className="left-border pl-2">{frontmatter.foundersTitle}</h2>
              <p className="lead">{frontmatter.foundersText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img fluid={data.datArtFoundersImage.childImageSharp.fluid} className="rounded" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-grey">
        <Container className="p-4">
          <Row>
            <Col md={{
              span: 7,
              order: 2,
            }}
            >
              <h2 className="left-border pl-2">{frontmatter.visionTitle}</h2>
              <p className="lead">{frontmatter.visionText}</p>
            </Col>
            <Col
              md={{
                span: 5,
                order: 1,
              }}
              className="my-auto"
            >
              <Img
                fluid={data.datArtVisionImage.childImageSharp.fluid}
                className="rounded"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white">
        <Container className="p-4">
          <Row>
            <Col md={7}>
              <h2 className="left-border pl-2">{frontmatter.missionTitle}</h2>
              <p className="lead">{frontmatter.missionText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img fluid={data.datArtMissionImage.childImageSharp.fluid} className="rounded" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-grey">
        <Container className="p-3">
          <div className="text-center">
            <h2 className="pl-2 left-border d-inline-block">{frontmatter.valuesTitle}</h2>
          </div>
          <Img
            fluid={data.datArtValuesImage.childImageSharp.fluid}
            className="rounded values-image-h mt-4"
          />
          <Row className="mt-1 justify-content-lg-around">
            <Col lg={6} className="p-4">
              {/* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> */}
              <h3 className="text-info">{frontmatter.respectTitle}</h3>
              <p>{frontmatter.respectFirstParagraphText}</p>
              <p>{frontmatter.respectSecondParagraphText}</p>
            </Col>
            <Col lg={6} className="p-4">
              {/* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> */}
              <h3 className="text-info">{frontmatter.commitmentTitle}</h3>
              <p>{frontmatter.commitmentFirstParagraphText}</p>
              <p>{frontmatter.commitmentSecondParagraphText}</p>
            </Col>
            <Col lg={6} className="p-4">
              <h3 className="text-info">{frontmatter.excellenceTitle}</h3>
              <p>{frontmatter.excellenceFirstParagraphText}</p>
              <p>{frontmatter.excellenceSecondParagraphText}</p>
            </Col>
            <Col lg={6} className="p-4">
              <h3 className="text-info">{frontmatter.clientSatisfactionTitle}</h3>
              <p>{frontmatter.clientSatisfactionFirstParagraphText}</p>
              <p>{frontmatter.clientSatisfactionSecondParagraphText}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

Presentation.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`  
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    fragment presentationFluidImage on File {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "about-us-presentation-datart"}}) {
            frontmatter {
                title
                description

                mainTitle

                foundersTitle
                foundersText
                visionTitle
                visionText
                missionTitle
                missionText
                
                valuesTitle
                respectTitle
                respectFirstParagraphText
                respectSecondParagraphText
                excellenceTitle
                excellenceFirstParagraphText
                excellenceSecondParagraphText
                commitmentTitle
                commitmentFirstParagraphText
                commitmentSecondParagraphText
                clientSatisfactionTitle
                clientSatisfactionFirstParagraphText
                clientSatisfactionSecondParagraphText
            }
        }
        
        datArtAboutUsHeaderImage: file(relativePath: {regex: "/datArtAboutUsHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }

        datArtFoundersImage: file(relativePath: {regex: "/datArtFoundersImage/"}) {
            ...presentationFluidImage
        }

        datArtVisionImage: file(relativePath: {regex: "/datArtVisionImage/"}) {
            ...presentationFluidImage
        }

        datArtMissionImage: file(relativePath: {regex: "/datArtMissionImage/"}) {
            ...presentationFluidImage
        }

        datArtValuesImage: file(relativePath: {regex: "/datArtValuesImage/"}) {
            ...presentationFluidImage
        }
    }
`;
